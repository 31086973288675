import { implementations as _tpaActionImplementations } from '@wix/data-binding-tpa-actions'
import '../helpers/polyfills'
import parseUrl from 'url-parse'
import { viewerAutomationsClientCreator } from '@wix/wix-code-automations-client'
import { Trace, Breadcrumb, AppError } from '../logger'
import FesDataFetcher from '../inverted-dependencies/FesDataFetcher'
import WixDataFetcher from '../inverted-dependencies/WixDataFetcher'
import { WarmupCache } from '../inverted-dependencies/WarmupCache'
import StaticCache from '../inverted-dependencies/StaticCache'
import { createFeatures } from '../inverted-dependencies/Features'
import Logger from '../inverted-dependencies/Logger'
import DataBinding from './DataBinding'
import { createListenersByEvent } from '../inverted-dependencies/createListenersByEvent'
import { createDataSchemasClientForBrowser } from '@wix/wix-data-schemas-client'
import { Platform } from '../inverted-dependencies/Platform'
import { createDatasetConfigs } from '../inverted-dependencies/helpers/utils'
import { createComponentFactory } from '../inverted-dependencies/components'
import i18nCreatorLite from '../helpers/i18nCreatorLite'
import { loadExpressionFunctions } from '@wix/wix-data-client-common-standalone'
import { createAutomationsV2Client } from '../inverted-dependencies/automationsV2Client'

const setWebpackPublicPathFromAppUrl = url => {
  try {
    __webpack_public_path__ = url.substr(0, url.lastIndexOf('/') + 1)// eslint-disable-line
  } catch {}
}

export default class App {
  constructor({
    verbose = false,
    //TODO: all this crap is in constructor, because it can be passed from IT tests. AAAAAAA!!!!!
    //TODO: kurva!!! should be removed after crappy it tests for internal business logic will be changed to units.
    //TODO: And WixDataFetcher integration with wix data and schemas should be tested separately
    wixDataSchemasForItTests,
    automationsClientCreator = viewerAutomationsClientCreator,
    tpaActionImplementations = _tpaActionImplementations,
  } = {}) {
    this.#wixDataSchemasForItTests = wixDataSchemasForItTests
    this.#automationsClientCreator = automationsClientCreator
    this.#verbose = verbose
    this.#tpaActionImplementations = tpaActionImplementations

    return {
      initAppForPage: this.initAppForPage,
      createControllers: this.createControllers,
    }
  }

  initAppForPage = (
    platformSettings,
    platformUtils,
    wixSdk,
    {
      bi = {},
      monitoring: { createMonitor },
      fedOpsLoggerFactory,
      biLoggerFactory,
      essentials: { httpClient, experiments },
    } = {},
  ) => {
    try {
      const platform = new Platform({
        platformUtils,
        wixSdk,
        bi,
        tpaActionImplementations: this.#tpaActionImplementations,
        devMode: false,
        verbose: this.#verbose,
      })
      const { settings } = platform
      const {
        instance,
        appData: { gridAppId },
        url,
      } = platformSettings
      setWebpackPublicPathFromAppUrl(url)

      const {
        data: wixData,
        window: { warmupData, getRouterData },
        location: { baseUrl, protocol },
        site: { language, currentPage },
      } = wixSdk

      this.#routerData = getRouterData()
      this.#logger = new Logger({
        fedops: {
          factory: fedOpsLoggerFactory,
          hooks: {
            start: ({ name }) =>
              this.#logger.log(
                new Breadcrumb({
                  category: 'interaction start',
                  message: `interaction ${name} started`,
                }),
              ),
            end: ({ name, duration }) =>
              this.#logger.log(
                new Breadcrumb({
                  category: 'interaction end',
                  message: `interaction ${name} ended after ${duration} ms`,
                }),
              ),
          },
        },
        bi: { factory: biLoggerFactory },
        monitor: { factory: createMonitor },
        verbose: {
          factory: () => ({
            // eslint-disable-next-line no-console
            log: (...args) =>
              (wixSdk.telemetry?.console || console).verbose(...args),
          }),
        },
        console: {
          factory: () => wixSdk.telemetry?.console || console,
        },
        settings,
        global: self,
      })

      this.#logger.log(
        new Trace('databinding/initAppForPage', Trace.types.START),
      )

      const i18n = i18nCreatorLite(language)

      const features = createFeatures({ experiments, settings })

      const dataFetcher = features.fes
        ? new FesDataFetcher({
            httpClient,
            getRequestParams: () => ({ instance, gridAppId }),
          })
        : new WixDataFetcher({
            wixData,
            wixDataSchemas:
              this.#wixDataSchemasForItTests ||
              createDataSchemasClientForBrowser(
                httpClient,
                instance,
                gridAppId,
                {
                  baseUrl: `${settings.env.editor ? 'https' : protocol}://${
                    parseUrl(baseUrl).hostname
                  }/_api/cloud-data`,
                  useApiV2: true,
                },
              ),
          })

      const warmupCache = new WarmupCache(warmupData)
      const staticCache = new StaticCache(this.#routerData)
      const listenersByEvent = createListenersByEvent({
        automationsClientCreator: () =>
          features.automationsClientV2
            ? createAutomationsV2Client(httpClient)
            : this.#automationsClientCreator({
                httpClient,
              }),
        pageId: currentPage.id,
      })

      this.#dataBinding = new DataBinding({
        platform,
        dataFetcher,
        warmupCache,
        staticCache,
        features,
        listenersByEvent,
        logger: this.#logger,
        i18n,
        global: self,
        loadExpressionFunctions,
      })

      this.#logger.log(new Trace('databinding/initAppForPage', Trace.types.END))

      return Promise.resolve()
    } catch (e) {
      this.#logger &&
        this.#logger.log(
          new AppError('App initialisation failed', { cause: e }),
        )
      return Promise.reject(e)
    }
  }

  createControllers = rawControllerConfigs => {
    if (!rawControllerConfigs.length) {
      return []
    }

    const datasetConfigs = createDatasetConfigs(
      rawControllerConfigs,
      this.#routerData,
    )

    const fireEventByDatasetId = rawControllerConfigs.reduce(
      (acc, { $w, compId }) => {
        acc[compId] = $w.fireEvent
        return acc
      },
      {},
    )

    return this.#dataBinding
      .initializeDatasets({
        datasetConfigs,
        firePlatformEvent: datasetId => fireEventByDatasetId[datasetId],
      })
      .map(dataset => ({
        ...dataset,
        pageReady: $w => dataset.pageReady(createComponentFactory($w)),
      }))
  }

  #dataBinding
  #logger
  #routerData

  #verbose

  #wixDataSchemasForItTests
  #automationsClientCreator
  #tpaActionImplementations
}
